import React from 'react';
import { useIntl } from '@itsa.io/web3utils';
import useStyles from 'styles/pages/ProfilePage';

const ProfilePage = () => {
	const { t } = useIntl();
	const classes = useStyles();

	return <div className={classes.root}>{t('page.profile.description')}</div>;
};

export default ProfilePage;
